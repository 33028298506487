<script>
    export let color = 'white';
    const onClick = link => {
        window.open(link, "_blank");
    }
</script>

<style lang="scss">
.wrapper {
    display: flex;
    align-items: flex-start;
    padding-top: 0.5vw;
    height: 2.6vw;
    
    img {
        max-height: 100%;
        margin: 0 0.5vw;
        cursor: pointer;
    }
    
    @media (max-width: 768px) {
        height: 7.5vw;
        width: 100%;
        justify-content: space-between;
    }
}
</style>

<div class="wrapper">    
    <img src={`assets/twitter-${color}.svg`} alt='logo twitter' on:click={() => onClick('https://twitter.com/ZenikaIT')} />
    <img src={`assets/youtube-${color}.svg`} alt='logo youtube' on:click={() => onClick('https://www.youtube.com/user/ZenikaITTv')} />
    <img src={`assets/facebook-${color}.svg`} alt='logo facebook' on:click={() => onClick('https://www.facebook.com/ZenikaIT')} />
    <img src={`assets/instagram-${color}.svg`} alt='logo instagram' on:click={() => onClick('https://www.instagram.com/zenika_it')} />
</div>
