<script>
    import Title from "./Title.svelte";
    import Button from "./Button.svelte";

    const buttonTitle = "Accéder aux keynotes"
    const buttonLink = "http://infos.zenika.com/next-2022-téléchargement-vidéos"
</script>

<style lang="scss">
  .fullsize {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  .wrapper {
    position: relative;
    @media (max-width: 768px) {
      margin-bottom: 22.2vw;
    }
  }

  .content-fullsize {
    margin: 5.8vw 5.2vw 0 5.2vw;

    .photos {
      display: flex;
      justify-content: space-between;
      background-color: black;
      border-radius: 25px;

      img {
        margin: 0 4.1vw;
      }
    }

    .descriptions {
      height: 22vw;
      margin-top: 2vw;
      font-size: 2vw;
      position: relative;
      text-align: center;
      .description {
        width: 19.8vw;
        position: absolute;
        p:first-child {
          font-weight: bold;
          margin-bottom: 2.1vw;
        }
      }
      .description:first-child {
        left: 0;
        margin-left: 1.1vw;
      }

      .description:nth-child(2) {
        left: 50%;
        transform: translate(-50%);
      }

      .description:nth-child(3) {
        right: 0;
        width: 24.8vw;
      }

      .button {
        position: absolute;
        right:0;
        top: 16.1vw;
      }
    }
  }

  .content-mobile {
    margin: 0 10.7vw 0 8.4vw;
    .content {
      background-color: black;
      border-radius: 5.6vw;
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        justify-content: space-between;
        margin: 6.35vw 10.4vw 9.75vw 6.1vw;

        img {
          height: 26.8vw;
          object-fit: contain;
        }

        .description {
          text-align: center;
          width: 34vw;

          p:first-child {
            font-weight: bold;
            font-size: 4.5vw;
            margin-bottom: 3.4vw;
          }

          p:nth-child(2) {
            font-size: 3.4vw;
          }
        }

        .description:last-child {
          width: 36vw;
        }
      }
    }
    .button {
      display: flex;
      justify-content: flex-end;
      margin-top: 8vw;
    }
  }

  .grapik {
    z-index:-1;
    position: absolute;
    width: 89.3vw;
    height: 44.43vw;
    left: -6vw;
    top:13vw;
    @media (max-width: 768px) {
      transform-origin: 25%;
      transform: scale(1.7, 1.3);
      left: 0;
      top:-4vw;
    }
  }

  .accent {
    position: absolute;
    left: 5.6vw;
    height: 71vw;
  }
</style>

<div class="wrapper">
  <img class="grapik fullsize" src="assets/grapik_1.svg" alt="forme gemetrique"/>
      <img class="accent mobile" src="assets/call-for-paper-accent.svg" alt="accent"/>
  <Title title="3 keynotes" subTitle="Craftsmanship, produit et innovation"/>

  <!-- Full size version -->
  <div class="content-fullsize fullsize">
    <div class="photos">
      <img src="assets/photo-1.png" alt="Portrait de Xavier Detant"/>
      <img src="assets/photo-2.png" alt="Portrait de Rhadika Dutt"/>
      <img src="assets/photo-3.png" alt="Portrait de Robin Béraud-Sudreau"/>
    </div>
    <div class="descriptions">
      <div class="description">
        <p>Xavier Detant</p>
        <p>Comment planter sa solution ultime et couler son bijou technologique ?</p>
      </div>
      <div class="description">
        <p>Rhadika Dutt</p>
        <p>Build radical, world-changing products while embracing responsibility</p>
      </div>
      <div class="description">
        <p>Robin Béraud-Sudreau</p>
        <p>Anatomie de l’innovation</p>
      </div>
      <div class="button">
        <Button title={buttonTitle} link={buttonLink}/>
      </div>
    </div>
  </div>

  <!-- Mobile version -->
  <div class="content-mobile mobile">
    <div class="content">
      <div class="item">
        <img src="assets/photo-1.png">
        <div class="description">
          <p>Xavier Detant</p>
          <p>Comment planter sa solution ultime et couler son bijou technologique ?</p>
        </div>
      </div>
      <div class="item">
        <img src="assets/photo-2.png">
        <div class="description">
          <p>Rhadika Dutt</p>
          <p>Build radical, world-changing products while embracing responsibility</p>
        </div>
      </div>
      <div class="item">
        <img src="assets/photo-3.png">
        <div class="description">
          <p>Robin Béraud-Sudreau</p>
          <p>Anatomie de l’innovation</p>
        </div>
      </div>
    </div>
    <div class="button">
      <Button title={buttonTitle} link={buttonLink}/>
    </div>
  </div>

</div>
