<script>
    export let title, subTitle = ''
</script>
<style lang="scss">
    p {
        margin: 0;
    }
    

    .wrapper {
        border-width:0 0 0 0.67vw;
        margin-left: 8.75vw;
        padding: .73vw 0 2vw 2vw;
        border-style: solid;
        border-image: linear-gradient(to bottom,  #EE2238,  #BF1D67 ) 1 100%;
        border-image-slice: 1;
        font-weight: bold;
        
        @media (max-width: 768px) {
            border: none;
        }
    }
    .title {
        font-size: 4.69vw;
        line-height: 6.7vw;
        white-space: pre-line;

        @media (max-width: 768px) {
            font-size: 10vw;
            line-height: 9vw;
            margin-bottom: 3vw;   
        }
    }
    
    .subtitle {
        font-size: 2.4vw;
        line-height: 3.4vw;
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 5.4vw;
            padding-right: 5vw;
        }
    }

</style>

<div class="wrapper">    
    <p class="title">{title}</p>
    <p class="subtitle">{subTitle}</p>
</div>
