<script>
import Socials from "./Socials.svelte";

</script>
<style lang="scss">
    .wrapper {
        width: 100%;
        background: transparent linear-gradient(97deg, #000000 0%, #EE2238 100%) 0% 0% no-repeat padding-box;
        height: 13vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @media (max-width: 768px) {
            height: 104vw;
            background: transparent linear-gradient(133deg, #000000 0%, #EE2238 100%) 0% 0% no-repeat padding-box;
            flex-direction: column;
            align-items: center;
        }

    }

    .signature {
        position: relative;
        font-size: 2.2vw;
        line-height: 2.6vw;
        font-weight: 100;
        margin-left: 5vw;
        a {
            order: 3;
            font-size: 1.25vw;
            letter-spacing: 0.0125vw;
        }
        @media (max-width: 768px) {
            order: 2;
            font-size: 9.5vw;
            line-height: 11.3vw;
            margin: 0;
            p {
                margin-bottom: 16.8vw;;
            }
            a {
                letter-spacing: 0.05vw;
                font-size: 5.4vw;

            }
        }
    }

    .links {
        font-size: 1.04vw;
        display: flex;
        a {
            margin: 0 2vw;
        }
        @media (max-width: 768px) {
            font-size: 3.6vw;
            padding-bottom: 8vw;
            justify-content: space-around;
            width: 100%;
            order: 3;
        }
    }

    .socials {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        @media (max-width: 768px) {
            position: relative;
            width: 70%;
            transform: none;
            left: auto;
            order: 1;
            margin-top: 13.4vw;
        }
    }

</style>

<div class="wrapper">    
    <div class="signature">
        <p>By Zenika</p>
        <a href="https://www.zenika.com">www.zenika.com</a>
        </div>
    <div class="socials">
        <Socials/>
    </div>
    <div class="links">
        <a href="mailto:marketing@zenika.com">Contact</a>
        <a href="https://www.zenika.com/legal">Mentions légales</a>
    </div>
</div>
