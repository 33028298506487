<script>
     import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
</script>

<style lang="scss">
    .logo :global(.lottie-player) {
        width: 84.9vw;
        height: 21.4vw;
        @media (max-width: 768px) {
            width: 110vw;
            height: 27.5vw;
        }
    } 
    
    .logo {
        display: flex;
        justify-content: center;
        width: 100%;    
        height: 21.4vw;
        margin-bottom: 3vw;
        
        @media (max-width: 768px) {
            display: flex;
            justify-content: center;
            height: 27.5vw;
            margin-bottom: 13.4vw;
            
        }
    }
</style>
        
<div class="logo">
    <LottiePlayer src="https://assets2.lottiefiles.com/private_files/lf30_unnrhfoi.json"  background="transparent"  speed="1"  autoplay/>
</div>