<script>
    import Title from "./Title.svelte";
    import Button from "./Button.svelte";

    const buttonTitle = "Accéder aux conférences"
    const buttonLink ="http://infos.zenika.com/next-2022-téléchargement-vidéos"
</script>

<style lang="scss">
  .fullsize {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .wrapper {
    margin-bottom: 10.3vw;
    position: relative;
  }

  .content {
    display: flex;
    justify-content: flex-end;
    margin-top: 3vw;
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .image {
    width: 43.4vw;

    @media (max-width: 768px) {
      width: 76.6vw;
      height: 58vw;
      margin-bottom: 10.7vw;
    }
  }


  .audiance {
    object-fit: cover;
    @media (max-width: 768px) {
      width: 76.6vw;
      height: 58vw;
    }
  }


  .description {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    text-align: center;
    line-height: 2.6vw;
    width: 50vw;
    flex-direction: column;
    font-size: 2vw;
    & span {
      font-weight: bold;
    }



    .text {
      width: 35vw;
      @media (max-width: 768px) {
        margin: 0 0 16vw 12vw;
        align-self: flex-start;
        width: 72.3vw;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      text-align: left;
      font-size: 4.5vw;
      line-height: 6.8vw;
      align-self: flex-start;
    }
  }

  .grapik-3 {
    z-index:-1;
    position: absolute;
    right:-44.5vw;
    top:-9.7vw;
    width: 73.7vw;
    height: 70.9vw;
  }

  .grapik-4 {
    @media (max-width: 768px) {
      z-index:-1;
      position: absolute;
      left: 22.7vw;
      top: 12vw;
    }
  }

  .grapik-2 {
    z-index:-1;
    position: absolute;
    top: -8.3vw;
    left: -3.6vw;
    width: 57.8vw;
    height: 56vw;
    @media (max-width: 768px) {
      left: -6vw;
      top: 49vw;
    }
  }

  .button {
    @media (max-width: 768px) {
      display: flex;
      justify-content: flex-end;
      padding-right: 5.9vw;
    }
  }

  .accent {
    position: absolute;
    left: 5.6vw;
    height: 95vw;
  }

</style>

<div class="wrapper">
  <img class="accent mobile" src="assets/inscrivez-vous-accent.svg" alt="accent"/>
  <Title title={`Nos replays\nproduit et craftsmanship`} />
  <div class="content">
    <div class="description">
      <div class="text">
        <span>De Michelin au Groupe Rocher en passant par Decathlon,</span>
        découvrez nos REX et conférences qui ont été donnés autour du produit et du craftsmanship
      </div>

      <div class="fullsize">
        <Button title={buttonTitle} link={buttonLink}/>
      </div>
    </div>

    <div class="image">
      <img class="audiance" src="assets/inscription.png" alt="inscrivez vous !"/>
    </div>
    <div class="mobile button">
      <Button title={buttonTitle} link={buttonLink}/>
    </div>
    <img class="grapik-3 fullsize" src="assets/grapik_3.svg" alt="forme gemetrique"/>
    <img class="grapik-4 mobile" src="assets/grapik_4.svg" alt="forme gemetrique"/>
    <img class="grapik-2" src="assets/grapik_2.svg" alt="forme gemetrique"/>
  </div>
</div>