<style lang="scss">
    .fullsize {
        @media (max-width: 768px) {
            display: none;
        }
    }

    .mobile {
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    }

    .wrapper {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 2vw;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 11.3vw;
        }

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;

            .number {
                font-size: 5.2vw;
                font-weight: bold;
                margin-bottom: 2vw;

                @media (max-width: 768px) {
                    margin-bottom: 3.4vw;
                    font-size: 4.6vw;
                }
            }

            .caption {
                font-size: 2.6vw;
                margin-bottom: 3.4vw;

                @media (max-width: 768px) {
                    margin-bottom: 7.7vw;
                    font-size: 4.6vw;
                }
            }
        }
    }

    .accent {
        background: transparent linear-gradient(-90deg, #EE2238 0%, #BF1D67 100%) 0% 0% no-repeat padding-box;
        width: 14.2vw;
        height: 0.7vw;
        @media (max-width: 768px) {
            background: transparent linear-gradient(90deg, #EE2238 0%, #BF1D67 100%) 0% 0% no-repeat padding-box;
            width: 48.5vw;
            height: 0.9vw;
            margin-bottom: 3.8vw;
        }
    }
</style>

<div class="wrapper">
    <div class="accent mobile"></div>
    <div class="item">
        <div class="number">130</div>
        <div class="caption">Participants</div>
    </div>
    <div class="item">
        <div class="number">21</div>
        <div class="caption">Speakers</div>
        <div class="accent fullsize"></div>
    </div>
    <div class="item">
        <div class="number">10</div>
        <div class="caption">Conférences</div>
    </div>
    <div class="accent mobile"></div>
</div>
